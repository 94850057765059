<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, reset }">
    <b-overlay :show="loading">
      <b-form @submit.prevent="handleSubmit(saveUpdate)" @reset.prevent="reset" autocomplete="off">
        <b-row>
          <b-col sm="12">
            <ValidationProvider name="Component" vid="component_id" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="component_id">
                <template v-slot:label>
                  {{ $t('orgProfile.component') }} <span class="text-danger">*</span>
                </template>
                  <b-form-select
                  plain
                  id="component_id"
                  v-model="form.component_id"
                  :options="componentList"
                  :state="errors[0] ? false : (valid ? true : null)"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value=0>{{$t('globalTrans.select')}}
                    </b-form-select-option>
                  </template>
                  </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="Menu Name (En)" vid="menu_name" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="menu_name">
                <template v-slot:label>
                  {{ $t('orgProfile.menu_name') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="menu_name"
                  v-model="form.menu_name"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="Menu Name (Bn)" vid="menu_name_bn" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="menu_name_bn">
                <template v-slot:label>
                  {{ $t('orgProfile.menu_name_bn') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="menu_name_bn"
                  v-model="form.menu_name_bn"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="URL" vid="url" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="url">
                <template v-slot:label>
                  {{ $t('orgProfile.url') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="url"
                  v-model="form.url"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="Icon" vid="icon" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="icon">
                <template v-slot:label>
                  {{ $t('orgProfile.icon') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="icon"
                  v-model="form.icon"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
          <b-col sm="12">
            <ValidationProvider name="Order" vid="order" rules="required" v-slot="{ errors }">
              <b-form-group
                label-for="order">
                <template v-slot:label>
                  {{ $t('orgProfile.order') }} <span class="text-danger">*</span>
                </template>
                <b-form-input
                  id="order"
                  v-model="form.sorting_order"
                  oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                  :state="errors[0] ? false : (valid ? true : null)"
                ></b-form-input>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
          </b-col>
        </b-row>
        <b-row class="text-right">
          <b-col>
              <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
              <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-form')">{{ $t('globalTrans.cancel') }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-overlay>
  </ValidationObserver>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { dashboardMenuStore, dashboardMenuUpdate } from '../../api/routes'
export default {
  name: 'Form',
  props: ['id'],
  data () {
    return {
      valid: null,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      errors: [],
      form: {
        component_id: 0,
        menu_name: '',
        menu_name_bn: '',
        url: '',
        icon: '',
        sorting_order: ''
      }
    }
  },
  created () {
    if (this.id) {
      const tmp = this.getData()
      this.form = tmp
    }
  },
  computed: {
    loading: function () {
      return this.$store.state.commonObj.loading
    },
    componentList () {
      return this.$store.state.CommonService.commonObj.componentList.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { value: item.value, text: item.text_bn }
        } else {
          return { value: item.value, text: item.text_en }
        }
      })
    }
  },
  methods: {
    async saveUpdate () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.id) {
        result = await RestApi.putData(commonServiceBaseUrl, `${dashboardMenuUpdate}/${this.id}`, this.form)
      } else {
        result = await RestApi.postData(commonServiceBaseUrl, dashboardMenuStore, this.form)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.dispatch('CommonService/mutateCommonObj', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-form')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    }
  }
}
</script>
